<template>
    <router-link class="smallCard" v-if="cardRoute" v-bind:to="cardRoute">
        {{ cardTitle }}
    </router-link>
</template>

<script>
export default {
    name: "smallCard",

    props: {
        cardTitle: String,
        cardRoute: String,
    },
};
</script>

<style lang="scss">
@import "./smallCard.scss";
</style>
