<template>
    <Layout class="knowledgeBaseSectionLayout">
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/knowledge-base`"
        />

        <div class="pageContent">
            <div class="knowledgeBaseSection">
                <div class="knowledgeBaseSection__content">
                    <div
                        class="knowledgeBaseSection__contentItem"
                        v-for="item in cardItems"
                        :key="item.cardTitle"
                    >
                        <SmallCard
                            :cardTitle="item.cardTitle"
                            :cardRoute="item.cardRoute"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import SmallCard from "@/views/components/SmallCard/SmallCard";
import PageHeader from "@/views/partials/pageHeader/pageHeader";

export default {
    name: "knowledgeBaseSection",

    components: {
        Layout,
        SmallCard,
        PageHeader,
    },

    data() {
        return {
            title: "С чего начать обучение?",
            items: [
                {
                    text: "База знаний",
                    to: `/${this.$route.params.slug}/knowledge-base`,
                },
                {
                    text: "с чего начать обучение",
                    active: true,
                },
            ],
            cardItems: [
                {
                    cardTitle: "Как писать конспект лекций?",
                    cardRoute: `/${this.$route.params.slug}/knowledge-base/single`,
                },
                {
                    cardTitle: "Зачем нужен вступительный тест",
                    cardRoute: `/${this.$route.params.slug}/knowledge-base/single`,
                },
                {
                    cardTitle: "Гайд для новичков",
                    cardRoute: `/${this.$route.params.slug}/knowledge-base/single`,
                },
                {
                    cardTitle: "Первые шаги",
                    cardRoute: `/${this.$route.params.slug}/knowledge-base/single`,
                },
                {
                    cardTitle: "Успешный старт",
                    cardRoute: `/${this.$route.params.slug}/knowledge-base/single`,
                },
            ],
        };
    },
};
</script>

<style lang="scss">
@import "./knowledgeBaseSection.scss";
</style>
